<section>
  <div
    class="relative overflow-hidden bg-no-repeat bg-cover"
    style="
      background-position: 50%;
      background-image: url('https://mdbcdn.b-cdn.net/img/new/slides/146.webp');
      height: 200px;
    "
  >
    <div
      class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
      style="background-color: rgba(0, 0, 0, 0.75)"
    >
      <div class="flex justify-center items-center h-full">
        <div class="text-center text-white px-6 md:px-12">
          <h1 class="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-12">
            A propos <br /><span>D'ABREMA</span>
          </h1>
          <button
            type="button"
            class="inline-block px-7 py-3 border-2 border-rose-700 text-white font-medium text-sm leading-snug uppercase rounded hover:bg-rose-800  focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
          >
            Commencer
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

